







































































































































































































































































































































































import {
  buildSalesOrderFormDto,
  buildTruckingSalesOrderLineFormDto,
  SearchBuilder,
} from "@/builder";
import currencyFilter from "@/filters/currency.filter";
import { displayNeg } from "@/helpers/common";
import { debounce } from "@/helpers/debounce";
import { generateUUID } from "@/helpers/uuid";
import {
  useAsset,
  useCalculator,
  useContactData,
  useCurrency,
  usePreferences,
  useSalesOrder,
  useSalesOrderCalculation,
  useTax,
  useTruckingSalesOrder,
} from "@/hooks";
import { TruckingSalesOrderMapper } from "@/mapper/TruckingSalesOrder.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ONE } from "@/models/constant/global.constant";
import { DATE_TIME_HOURS_DEFAULT_FORMAT } from "@/models/constants/date.constant";
import { AssetStateEnum } from "@/models/enums/master-asset.enum";
import SALES_ORDER_STATUS from "@/models/enums/sales-order.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { AddressDataDto } from "@/models/interface/contact-data";
import { AssetResponseDto } from "@/models/interface/master-asset";
import { AccountingTaxResponseDto } from "@/models/interface/master-tax";
import { IPreferencesResponseDto } from "@/models/interface/preference";
import {
  TruckingSalesOrderFormDto,
  TruckingSalesOrderLineFormDtoRow,
  TruckingSalesOrderRequestDto,
} from "@/models/interface/trucking-sales-order";
import { LabelInValue } from "@/types";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import Decimal from "decimal.js-light";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import SelectBranch from "../custom/select/SelectBranch.vue";
import SelectCurrency from "../custom/select/SelectCurrency.vue";
import SelectCustomer from "../custom/select/SelectCustomer.vue";
import SelectTaxCalculation from "../custom/select/SelectTaxCalculation.vue";
import SelectTermOfPayment from "../custom/select/SelectTermOfPayment.vue";
import { SelectTaxVatOut } from "../Tax";
import DisplayTotal from "./DisplayTotal.vue";

@Component({
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  components: {
    SelectBranch,
    SelectCustomer,
    SelectTaxCalculation,
    SelectCurrency,
    SelectTaxVatOut,
    DisplayTotal,
    SelectTermOfPayment,
  },
})
export default class FormMutate extends MNotification {
  @Prop({ type: String, required: false })
  id!: string;

  DATE_TIME_HOURS_DEFAULT_FORMAT = DATE_TIME_HOURS_DEFAULT_FORMAT;

  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  generateUUID = generateUUID;

  storeBaseDecimalPlace!: number;

  get isCreate(): boolean {
    return this.formData.status === ("" as SALES_ORDER_STATUS);
  }

  get isDraft(): boolean {
    return this.formData.status == SALES_ORDER_STATUS.DRAFT;
  }

  get isSubmitted(): boolean {
    return this.formData.status === SALES_ORDER_STATUS.SUBMITTED;
  }

  get isCreatedFromTruckingApp(): boolean {
    return !!this.formData.documentReference;
  }

  get isIdr(): boolean {
    return (
      !!this.formData.currency?.label && this.formData.currency?.label === "IDR"
    );
  }

  get isTaxNone(): boolean {
    return this.formData.taxCalculation?.key === TAX_CALCULATION.NONE;
  }

  get getTotalDpp(): number {
    const { sum } = useCalculator();
    const total = sum(this.formData.salesOrderLines.map(e => e.dpp || 0));
    return displayNeg(total);
  }

  get getTotalTax(): number {
    const { sum } = useCalculator();
    const total = sum(this.formData.salesOrderLines.map(e => e.taxAmount || 0));
    return displayNeg(total);
  }

  get getTotalDiscount(): number {
    const { sum } = useCalculator();
    const total = sum(this.formData.salesOrderLines.map(e => e.discount || 0));
    return displayNeg(total);
  }

  get getGrandTotal(): number {
    const grandTotal: number = new Decimal(this.getTotalDpp || 0)
      .plus(this.getTotalTax || 0)
      .toNumber();

    return displayNeg(grandTotal);
  }

  loading = {
    find: false,
    cancel: false,
    update: false,
    submit: false,
    draft: false,
    getTrucks: false,
    getTaxCodes: false,
  };

  @Ref("form")
  form!: FormModel;

  formData: TruckingSalesOrderFormDto = buildSalesOrderFormDto();
  shipToOptions: Option[] = [];
  billToOptions: Option[] = [];

  truckOptions: Option<AssetResponseDto>[] = [];
  taxCodeOptions: Option<AccountingTaxResponseDto>[] = [];

  formRules = {
    branch: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
        trigger: "change",
      },
    ],
    date: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
        trigger: "change",
      },
    ],
    customer: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
        trigger: "change",
      },
    ],
    shippingAddress: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
        trigger: "change",
      },
    ],
    billingAddress: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
        trigger: "change",
      },
    ],
    taxCalculation: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
        trigger: "change",
      },
    ],
    currency: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
        trigger: "change",
      },
    ],
    termOfPayment: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
        trigger: "change",
      },
    ],
    salesOrderLines: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
        trigger: "change",
      },
    ],
  };

  columns = [
    {
      title: this.$t("lbl_document_reference"),
      dataIndex: "documentReference",
      width: "200px",
      scopedSlots: { customRender: "documentReference" },
    },
    {
      title: this.$t("lbl_part_number_unit_code"),
      dataIndex: "unitCode",
      width: "200px",
      scopedSlots: { customRender: "unitCode" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "brand",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_specification"),
      dataIndex: "specification",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_unit_of_measurement"),
      dataIndex: "unitOfMeasurement",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_qty"),
      width: "200px",
      dataIndex: "qty",
      scopedSlots: { customRender: "qty" },
    },
    {
      title: this.$t("lbl_sales_name"),
      dataIndex: "salesName",
      width: "200px",
      scopedSlots: { customRender: "salesName" },
    },
    {
      title: this.$t("lbl_price"),
      width: "200px",
      dataIndex: "price",
      scopedSlots: { customRender: "price" },
    },
    {
      title: this.$t("lbl_discount"),
      width: "200px",
      dataIndex: "discount",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_dpp"),
      width: "200px",
      dataIndex: "dpp",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_tax_amount"),
      width: "200px",
      dataIndex: "taxAmount",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_tax_code"),
      dataIndex: "taxCode",
      width: "250px",
      scopedSlots: { customRender: "taxCode" },
    },
    {
      title: this.$t("lbl_subtotal"),
      width: "200px",
      dataIndex: "subtotal",
      customRender: text => currencyFilter(text),
    },
  ];

  selectedRowKeys: string[] = [];

  //#region change handlers

  //#region customer change handler
  /**
   *
   * @description on SelectCustomer value change, set the shipping address, billing address, and default term of payment.
   */
  onCustomerChange(e?: LabelInValue): void {
    this.formData.termOfPayment = 0;
    this.form.clearValidate(["shippingAddress", "billingAddress"]);

    if (!e || !e.key) {
      return;
    }

    const customerId = String(e.key);

    const { findOne } = useContactData();
    findOne(customerId).then(cust => {
      const addresses: AddressDataDto[] = cust.addressDataList;

      this.setDefaultBillingAndShippingAddresses(addresses);
      this.setBillingAndShippingAddressOptions(addresses);
      this.formData.termOfPayment = cust.top || 0;
    });
  }

  setDefaultBillingAndShippingAddresses(addresses: AddressDataDto[]): void {
    const { getDefaultShipToAddress, getDefaultBillToAddress } =
      useContactData();

    const shipAddress = getDefaultShipToAddress(addresses);
    const billAddress = getDefaultBillToAddress(addresses);

    this.formData.shippingAddress = {
      key: shipAddress,
      label: shipAddress,
    };
    this.formData.billingAddress = {
      key: billAddress,
      label: billAddress,
    };
  }

  setBillingAndShippingAddressOptions(addresses: AddressDataDto[]): void {
    const { toShipToAddressOptions, toBillToAddressOptions } = useContactData();

    this.shipToOptions = toShipToAddressOptions(addresses);
    this.billToOptions = toBillToAddressOptions(addresses);
  }
  //#endregion

  /**
   * @description set serialNumber, type, uom, spec, equipment, and brand every time asset is selected
   */
  onAssetChange(
    e: Option<AssetResponseDto> | undefined,
    row: TruckingSalesOrderLineFormDtoRow
  ): void {
    const id = e?.key;
    if (!id) {
      row.serialNumber = "-";
      row.type = "-";
      row.unitOfMeasurement = "-";
      row.unitOfMeasurementId = "-";
      row.specification = "-";
      row.equipment = "-";
      row.brand = "-";
      return;
    }

    const asset = this.findAssetFromOptions(String(id), row);
    if (!asset) {
      return;
    }
    row.serialNumber = asset.serialNumber;
    row.type = asset.type;
    row.unitOfMeasurement = asset.uomName;
    row.unitOfMeasurementId = asset.uomId;
    row.specification = asset.description;

    const assetCategoryIdSegments = asset.assetCategory.id.split(".");
    row.equipment = assetCategoryIdSegments[0].toUpperCase() ?? "-";
    row.brand = assetCategoryIdSegments[1].toUpperCase() ?? "-";
  }

  findAssetFromOptions(
    id: string,
    row: TruckingSalesOrderLineFormDtoRow
  ): AssetResponseDto | undefined {
    const fromRow = row.truckOptions.find(e => id === e.key);
    if (fromRow) {
      return fromRow.meta;
    }

    const fromDefault = this.truckOptions.find(e => id === e.key);
    if (fromDefault) {
      return fromDefault.meta;
    }

    return undefined;
  }

  onTaxCodeChange(
    e: Option<AccountingTaxResponseDto> | undefined,
    row: TruckingSalesOrderLineFormDtoRow
  ): void {
    const id = e?.key;
    if (!id) {
      return;
    }

    const taxCode = this.findTaxCodeFromOptions(String(id), row);
    if (!taxCode) {
      return;
    }

    row.taxCode = {
      key: taxCode.id,
      label: taxCode.code,
    };
    row.taxRate = taxCode.rate || 0;

    this.calculateSalesOrderLines();
  }

  findTaxCodeFromOptions(
    id: string,
    row: TruckingSalesOrderLineFormDtoRow
  ): AccountingTaxResponseDto | undefined {
    const fromRow = row.taxCodeOptions.find(e => id === e.key);
    if (fromRow) {
      return fromRow.meta;
    }

    const fromDefault = this.taxCodeOptions.find(e => id === e.key);
    if (fromDefault) {
      return fromDefault.meta;
    }

    return undefined;
  }

  onSelectedTableRowChange(keys: string[]): void {
    this.selectedRowKeys = keys;
  }

  onTaxCalculationChange(taxCalculation: LabelInValue): void {
    if (!taxCalculation) {
      return;
    }

    if (taxCalculation.key === TAX_CALCULATION.NONE) {
      const { findByTaxRateAndType } = useTax();
      findByTaxRateAndType(0, "VAT_OUT").then(taxOption => {
        this.formData.salesOrderLines.forEach(item => {
          item.taxCode = {
            key: taxOption.data[0]?.id ?? "",
            label: taxOption.data[0]?.code ?? "",
          };
          item.taxRate = taxOption.data[0]?.rate ?? 0;
        });

        this.calculateSalesOrderLines();
      });
    } else {
      this.calculateSalesOrderLines();
    }
  }

  onCurrencyChange(): void {
    const { findConversion } = useCurrency();
    const { findBaseCurrency } = usePreferences();

    const baseCurrency = findBaseCurrency();
    const defaultCurrency = baseCurrency?.name ?? "";

    const base = defaultCurrency;
    const to = this.formData.currency?.label ?? "";

    this.formData.currencyRate = ONE;
    if (!to || !base) return;
    findConversion(base, to).then(({ data }) => {
      const [curr] = data;
      this.formData.currencyRate = curr?.rate || ONE;
    });
  }
  //#endregion

  //#region truck event handling for each row
  onSearchTruck(
    record: TruckingSalesOrderLineFormDtoRow,
    value?: string
  ): void {
    debounce(() => {
      const builder = new SearchBuilder();
      const { filterBy } = useAsset();
      const params = new RequestQueryParams();
      const query: string[] = [this.buildTruckQuery()];
      const filtered: string = filterBy({ unitCode: value });

      if (filtered) {
        query.push(filtered);
      }

      params.search = query.join(builder.AND);

      record.loadingTrucks = true;

      this.fetchTruckOptions(params)
        .then(res => {
          record.truckOptions = res;
        })
        .finally(() => (record.loadingTrucks = false));
    });
  }

  buildTruckQuery(): string {
    const builder = new SearchBuilder();
    const q: string[] = [];

    q.push(
      builder.push(["status", AssetStateEnum.RETIRED], { not: true }).build()
    );

    q.push(
      builder
        .push(["assetCategory.categoryId", "truk"], { like: "end" })
        .build()
    );

    return q.join(builder.AND);
  }

  async fetchTruckOptions(
    params: RequestQueryParams = new RequestQueryParams()
  ): Promise<Option<AssetResponseDto>[]> {
    const { findAllAsset, toOptionsNew } = useAsset();
    const res = await findAllAsset(params);
    return toOptionsNew(res.data);
  }
  //#endregion

  //#region tax code event handling for each row
  onSearchTaxCode(
    record: TruckingSalesOrderLineFormDtoRow,
    value?: string
  ): void {
    debounce(() => {
      const { filterBy } = useTax();

      const params = new RequestQueryParams();
      const builder = new SearchBuilder();

      const query: string[] = [builder.push(["taxType", "VAT_OUT"]).build()];
      const filtered: string = filterBy({
        description: value,
      });
      if (filtered) {
        query.push(filtered);
      }
      params.search = query.join(new SearchBuilder().AND);

      record.loadingTaxCodes = true;

      this.fetchTaxCodeOptions(params)
        .then(res => {
          record.taxCodeOptions = res;
        })
        .finally(() => (record.loadingTaxCodes = false));
    });
  }

  async fetchTaxCodeOptions(
    params: RequestQueryParams = new RequestQueryParams()
  ): Promise<Option<AccountingTaxResponseDto>[]> {
    const { findCollections, toOptions } = useTax();
    const res = await findCollections(params);
    return toOptions(res.data);
  }
  //#endregion

  //#region button on click handlers
  /**
   * @description when adding row, set the default tax code and tax rate based on tax calculation and user preference
   */
  async handleAddRow(): Promise<void> {
    const { findById, findByTaxRateAndType } = useTax();

    const row = buildTruckingSalesOrderLineFormDto();
    row.key = this.formData.salesOrderLines.length;

    const salesTax: IPreferencesResponseDto | undefined =
      this.$store.getters["preferenceStore/getFeatSalesTaxRate"];
    const taxCalculation = this.formData.taxCalculation?.label;

    try {
      if (
        taxCalculation !== TAX_CALCULATION.NONE &&
        salesTax &&
        salesTax.value
      ) {
        const tax = await findById(salesTax.value);
        row.taxCode = {
          key: tax.id || "",
          label: tax.code || "",
        };
        row.taxRate = tax.rate || 0;
      } else if (taxCalculation === TAX_CALCULATION.NONE) {
        const taxOption = await findByTaxRateAndType(0, "VAT_OUT");
        row.taxCode = {
          key: taxOption.data[0]?.id ?? "",
          label: taxOption.data[0]?.code ?? "",
        };
        row.taxRate = taxOption.data[0]?.rate ?? 0;
      }
    } catch (error) {
      row.taxCode = {
        key: "",
        label: "",
      };
      row.taxRate = 0;
    }

    this.formData.salesOrderLines.push(row);
  }

  /**
   * @description when deleting row, store every deleted sales order line id to form data
   */
  handleDeleteRow(): void {
    this.showConfirmationDeleteItems(() => {
      this.formData.salesOrderLines = this.formData.salesOrderLines.filter(
        line => {
          const keep = !this.selectedRowKeys.includes(line.rowId);

          if (!keep && !!line.id) {
            this.formData.deletedSalesOrderLineIds.push(line.id);
          }

          return keep;
        }
      );

      this.selectedRowKeys = [];
      this.calculateSalesOrderLines();
    });
  }

  handleBack(): void {
    this.$router.push({ name: "trucking.sales-order" });
  }

  handleSubmit(): void {
    this.validateForm(() => {
      if (this.formData.status === SALES_ORDER_STATUS.DRAFT) {
        this.submitSalesOrder();
      } else {
        this.createSalesOrder();
      }
    });
  }

  handleSaveDraft(): void {
    const { create } = useTruckingSalesOrder();

    const req: TruckingSalesOrderRequestDto =
      TruckingSalesOrderMapper.formModelToSalesOrderRequestDto(
        this.formData,
        this.getTotalDpp,
        this.getTotalTax,
        this.getTotalDiscount,
        this.getGrandTotal
      );
    req.state = SALES_ORDER_STATUS.DRAFT;

    this.loading.draft = true;
    create(req)
      .then(res => {
        this.showNotifSuccess("notif_create_success", {
          documentNumber: res.documentNumber,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading.draft = false;
      });
  }

  handleUpdate(): void {
    this.validateForm(async () => {
      const { update } = useTruckingSalesOrder();

      const req: TruckingSalesOrderRequestDto =
        TruckingSalesOrderMapper.formModelToSalesOrderUpdateRequestDto(
          this.formData,
          this.getTotalDpp,
          this.getTotalTax,
          this.getTotalDiscount,
          this.getGrandTotal
        );

      this.loading.update = true;
      update(this.id, req)
        .then(res => {
          this.formData.deletedSalesOrderLineIds = [];
          this.showNotifSuccess("notif_update_success", {
            documentNumber: res.documentNumber,
          });
          this.getSalesOrderDetail();
        })
        .finally(() => (this.loading.update = false));
    });
  }

  handleCancel(): void {
    const { cancel } = useSalesOrder();

    this.loading.cancel = true;
    cancel(this.id)
      .then(() => {
        this.showNotifSuccess("notif_cancel_success");
        this.handleBack();
      })
      .finally(() => {
        this.loading.cancel = false;
      });
  }
  //#endregion

  calculateSalesOrderLines(): void {
    const { calculate } = useSalesOrderCalculation();
    const taxCalculation = this.formData.taxCalculation
      ?.label as TAX_CALCULATION;

    if (!taxCalculation) {
      return;
    }

    this.formData.salesOrderLines.forEach(line => {
      const { dpp, subtotal, taxAmount } = calculate(
        line.price,
        line.qty,
        line.discount,
        line.taxRate,
        taxCalculation
      );

      line.taxAmount = taxAmount;
      line.dpp = dpp;
      line.subtotal = subtotal;
    });
  }

  createSalesOrder(): void {
    const { create } = useTruckingSalesOrder();

    const req: TruckingSalesOrderRequestDto =
      TruckingSalesOrderMapper.formModelToSalesOrderRequestDto(
        this.formData,
        this.getTotalDpp,
        this.getTotalTax,
        this.getTotalDiscount,
        this.getGrandTotal
      );
    req.state = SALES_ORDER_STATUS.SUBMITTED;

    this.loading.submit = true;
    create(req)
      .then(res => {
        this.showNotifSuccess("notif_submit_success", {
          documentNumber: res.documentNumber,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  submitSalesOrder(): void {
    const { submit } = useTruckingSalesOrder();

    const req: TruckingSalesOrderRequestDto =
      TruckingSalesOrderMapper.formModelToSalesOrderUpdateRequestDto(
        this.formData,
        this.getTotalDpp,
        this.getTotalTax,
        this.getTotalDiscount,
        this.getGrandTotal
      );
    this.loading.submit = true;

    submit(this.id, req)
      .then(res => {
        this.showNotifSuccess("notif_submit_success", {
          documentNumber: res.documentNumber,
        });
        this.handleBack();
      })
      .finally(() => (this.loading.submit = false));
  }

  validateForm(callback: () => void): void {
    this.form.validate((valid: boolean) => {
      const isSalesOrderLinesInvalid = !!this.formData.salesOrderLines.find(
        line => !line.unitCode
      );

      if (!valid || isSalesOrderLinesInvalid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      callback();
    });
  }

  getSalesOrderDetail(): void {
    const { findById } = useSalesOrder();

    this.loading.find = true;
    findById(this.id)
      .then(res => {
        this.formData =
          TruckingSalesOrderMapper.salesOrderResponseDtoToFormModel(res);
        this.calculateSalesOrderLines();
      })
      .finally(() => (this.loading.find = false));
  }

  getInitialSelectValues(): void {
    this.loading.getTrucks = true;
    const truckParams = new RequestQueryParams();
    truckParams.search = this.buildTruckQuery();
    this.fetchTruckOptions(truckParams)
      .then(res => (this.truckOptions = res))
      .finally(() => (this.loading.getTrucks = false));

    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    params.search = builder.push(["taxType", "VAT_OUT"]).build();
    this.loading.getTaxCodes = true;
    this.fetchTaxCodeOptions(params)
      .then(res => (this.taxCodeOptions = res))
      .finally(() => (this.loading.getTaxCodes = false));
  }

  created(): void {
    if (this.id) {
      this.getSalesOrderDetail();
    }

    this.getInitialSelectValues();
  }

  @Watch("isCreatedFromTruckingApp")
  onChangeIsCreatedFromTruckingApp(): void {
    if (!this.isCreatedFromTruckingApp) {
      return;
    }

    this.columns.push({
      title: this.$t("lbl_description"),
      width: "200px",
      dataIndex: "description",
      customRender: text => text || "-",
    });
  }
}
