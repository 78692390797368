var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-model",
        {
          attrs: {
            model: _vm.detail,
            "wrapper-col": { span: 12 },
            "label-col": { span: 9 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_sales_order_number") } },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: "",
                          "data-testid": "trucking-sales-order-number"
                        },
                        model: {
                          value: _vm.detail.documentNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "documentNumber", $$v)
                          },
                          expression: "detail.documentNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_pi_number") } },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: "",
                          "data-testid":
                            "trucking-sales-order-document-reference"
                        },
                        model: {
                          value: _vm.detail.documentReference,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "documentReference", $$v)
                          },
                          expression: "detail.documentReference"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_branch") } },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: "",
                          "data-testid": "trucking-sales-order-branch"
                        },
                        model: {
                          value: _vm.detail.branchWarehouse,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "branchWarehouse", $$v)
                          },
                          expression: "detail.branchWarehouse"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_date") } },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: "",
                          "data-testid": "trucking-sales-order-date"
                        },
                        model: {
                          value: _vm.detail.date,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "date", $$v)
                          },
                          expression: "detail.date"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_customer") } },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: "",
                          "data-testid": "trucking-sales-order-customer"
                        },
                        model: {
                          value: _vm.detail.customerName,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "customerName", $$v)
                          },
                          expression: "detail.customerName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_shipping_address") } },
                    [
                      _c("a-textarea", {
                        attrs: {
                          "data-testid":
                            "trucking-sales-order-shipping-address",
                          "auto-size": "",
                          disabled: ""
                        },
                        model: {
                          value: _vm.detail.customerShipToAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "customerShipToAddress", $$v)
                          },
                          expression: "detail.customerShipToAddress"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_billing_address") } },
                    [
                      _c("a-textarea", {
                        attrs: {
                          "data-testid": "trucking-sales-order-billing-address",
                          "auto-size": "",
                          disabled: ""
                        },
                        model: {
                          value: _vm.detail.customerBillToAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "customerBillToAddress", $$v)
                          },
                          expression: "detail.customerBillToAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_tax_calculation") } },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: "",
                          "data-testid": "trucking-sales-order-tax-calculation"
                        },
                        model: {
                          value: _vm.detail.taxCalculation,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "taxCalculation", $$v)
                          },
                          expression: "detail.taxCalculation"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_currency") } },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: "",
                          "data-testid": "trucking-sales-order-currency"
                        },
                        model: {
                          value: _vm.detail.currency,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "currency", $$v)
                          },
                          expression: "detail.currency"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_term_of_payment") } },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: "",
                          "data-testid": "trucking-sales-order-term-of-payment"
                        },
                        model: {
                          value: _vm.detail.termOfPayment,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "termOfPayment", $$v)
                          },
                          expression: "detail.termOfPayment"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_notes") } },
                    [
                      _c("a-textarea", {
                        attrs: {
                          disabled: "",
                          "data-testid": "trucking-sales-order-notes"
                        },
                        model: {
                          value: _vm.detail.notes,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "notes", $$v)
                          },
                          expression: "detail.notes"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_status") } },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: "",
                          "data-testid": "trucking-sales-order-status"
                        },
                        model: {
                          value: _vm.detail.states,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "states", $$v)
                          },
                          expression: "detail.states"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          "data-source": _vm.detail.salesOrderLines,
          columns: _vm.columns,
          "row-key": function(r) {
            return r.id
          },
          pagination: {
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            },
            showSizeChanger: true
          },
          scroll: { x: "calc(1400px + 100%)" },
          size: "small",
          "row-class-name": function(_record, index) {
            return index % 2 ? "bg-white" : "bg-gray-light"
          },
          "data-testid": "trucking-sales-order-lines"
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_c("span", [_vm._v(_vm._s(_vm.$t("lbl_product")))])]
            },
            proxy: true
          }
        ])
      }),
      _c("a-divider"),
      _c("DisplayTotal", {
        attrs: {
          total: _vm.detail.total,
          totalDiscount: _vm.detail.totalDiscount,
          totalTax: _vm.detail.totalTax,
          grandTotal: _vm.detail.grandTotal,
          "data-testid": "trucking-sales-order-total"
        }
      }),
      _c("a-divider"),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-button",
                {
                  attrs: { "data-testid": "trucking-sales-order-back-button" },
                  on: { click: _vm.handleBack }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }