

































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class DisplayTotal extends Vue {
  @Prop({ type: Number, required: true, default: 0 })
  total!: number;

  @Prop({ type: Number, required: true, default: 0 })
  totalTax!: number;

  @Prop({ type: Number, required: true, default: 0 })
  totalDiscount!: number;

  @Prop({ type: Number, required: true, default: 0 })
  grandTotal!: number;
}
