



















































































































































import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import quantityFilter from "@/filters/quantity.filter";
import { useSalesOrder } from "@/hooks";
import { SalesOrderResponseDto } from "@/models/interface/sales-order";
import { Component, Prop, Vue } from "vue-property-decorator";
import { DisplayBoolean } from "../DisplayBoolean";
import DisplayTotal from "./DisplayTotal.vue";

@Component({
  components: {
    DisplayBoolean,
    DisplayTotal,
  },
})
export default class FormDetail extends Vue {
  @Prop({ type: String, default: "", required: true })
  id!: string;

  detail: SalesOrderResponseDto = useSalesOrder().initDetailDto();

  columns = [
    {
      title: this.$t("lbl_document_reference"),
      dataIndex: "documentReference",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_part_number_unit_code"),
      dataIndex: "productCode",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "productName",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "merk",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_spec"),
      dataIndex: "specification",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_unit_of_measurement"),
      dataIndex: "uomName",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      customRender: text => quantityFilter(text),
    },
    {
      title: this.$t("lbl_sales_name"),
      dataIndex: "salesName",
      width: "200px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_discount"),
      dataIndex: "discountValue",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_dpp"),
      dataIndex: "taxableValue",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_tax_amount"),
      dataIndex: "taxValue",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_tax_code"),
      dataIndex: "taxCode",
      width: "250px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_subtotal"),
      dataIndex: "subTotal",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      customRender: text => text || "-",
    },
  ];

  getDetail(id: string): void {
    const { findById, mapDetailToForm } = useSalesOrder();
    findById(id).then(res => {
      this.detail = { ...res };
      this.detail.date = dateFormat(res.date);
      mapDetailToForm(res);
    });
  }

  handleBack(): void {
    this.$router.push({
      name: "trucking.sales-order",
    });
  }

  mounted(): void {
    this.getDetail(this.id);
  }
}
